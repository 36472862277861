import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, IAlgoliaSearchRequest, Page} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {
    InputStackedTextComponent
} from "@library/shared/input/input-stacked-text.component";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {OnDestroyPage} from "@library/shared/_inherited/ondestroy.page";
import {InputSelectComponent} from "@library/shared/input/input-select.component";
import {Airport, getCityName} from '@nxt/model-laro';
import algoliasearch from 'algoliasearch/lite';
// @ts-ignore
import {environment} from '@env/environment';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {SimpleSearch} from '@library/nxt/search/simple-search';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, RouterModule,
        InputStackedTextComponent, InputSelectComponent, PipesModule, SimpleSearch
    ],
    selector: 'input-airport',
    template: `
        <div #parent [formGroup]="localForm" [class]="class ? class : 'w-full bg-white text-left rounded-2xl text-gray-500'">
            <div class="p-1">
                <div class="flex ml-3 left-0 bottom-0" *ngIf="!!label">
                    <label [for]="controlName">{{ label }}</label>
                </div>
                <input type="search"
                       [placeholder]="placeholder"
                       [formControlName]="controlName"
                       [class]="inputClass ? inputClass : 'w-full mt-0 text-gray-500 rounded-b-2xl text-sm border-0 bg-transparent'"
                />
                <p *ngIf="form?.get(controlName).touched && form.get(controlName)?.hasError('required')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Required
                </p>
            </div>
        </div>
        <div #child *ngIf="show" [style.right]="rightPos" class="absolute z-10 mt-0 flex w-screen max-w-max px-4">
            <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div class="bg-gray-100 p-4">
                    <ng-container *ngFor="let hit of hits">
                        <div (click)="setAirport(hit);show=false;" class=" p-3 hover:font-bold cursor-pointer">
                            <div class="truncate">{{ hit.icao }}: {{ hit.name }}  ({{ getCityName(hit) }})</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div [formGroup]="form">
            <input type="hidden"
                   [placeholder]="placeholder"
                   [formControlName]="controlName"
                   [id]="controlName"
                   class="h-0 w-0 hidden"
            />
        </div>
    `
})
export class InputAirport extends OnDestroyPage implements OnChanges {
    @ViewChild('parent', {static: false}) parent: ElementRef;
    @ViewChild('child', {static: false}) child: ElementRef;
    @Output() onChange: EventEmitter<Airport> = new EventEmitter<Airport>();
    @Input() inputClass: string;
    @Input() class: string = '';
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() value: Date;
    @Input() minDate: Date;
    @Input() placeholder: string = '';
    @Input() exclude: Airport;
    @Input() zIndex: string = ''
    localForm: UntypedFormGroup;
    show: boolean = false;
    builder: AlgoliaQueryBuilder = new AlgoliaQueryBuilder();
    hits: Airport[];
    getCityName = getCityName;
    rightPos: string;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.form && this.controlName && !this.localForm) {

            let data: any = {};
            let selected: Airport = this.form.get(this.controlName).value;
            if (this.exclude && this.exclude?.icao === selected?.icao) {
                selected = null;
            }
            data[this.controlName] = [selected ? this.airportDisplay(selected) : ''];
            this.localForm = this.fb.group(data);

            this.localForm.get(this.controlName).valueChanges
                .pipe(
                    debounceTime(550),
                    filter((val) => !   val || val.length >= 3)
                )
                .pipe(takeUntil(this.d$))
                .subscribe(d => {
                    this.searchAirports(d);
                });

        }
    }

    airportDisplay(airport: Airport): string {
        if (airport?.icao) {
            if (airport?.name) {
                return `${airport.icao} : ${airport.name} (${getCityName(airport)})`;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    async searchAirports(value: string) {
        if (!value) {

            this.hits = [];
            this.form.get(this.controlName).setValue('', { emitEvent: false });
            this.show = false;

        } else if (value) {
            this.builder.query = value;
            let iata: AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem({
                id: 'iata',
                name: '',
                key: 'iata',
                type: 'facet',
                key_unique: true,
                value: `iata:${this.builder.query}`
            });
            this.builder.removeFilter(iata);
            if (this.builder.query?.length === 3) {
                this.builder.addFilter(iata);
                this.builder.query = '';
            }

            if (environment.algolia) {
                const aClient = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
                const index = aClient.initIndex('airports');
                let req: IAlgoliaSearchRequest = Object.assign({}, this.builder.toSearchRequest());

                let results: any = await index.search(req.query, req.params);

                results?.hits?.sort((a, b) => {
                    let aN: number = 0;
                    if (a.icao === req.query?.toUpperCase().trim()) {
                        aN += 10;
                    } else if (a.iata === req.query?.toUpperCase()) {
                        aN += 5
                    }
                    if (a['_highlightResult']?.name?.matchLevel === 'full') {
                        aN += 1
                    }
                    if (a['_highlightResult']?.city?.name?.matchLevel === 'full') {
                        aN += 1
                    }
                    let bN: number = 0;
                    if (b.icao === req.query?.toUpperCase() || b.iata === req.query?.toUpperCase()) {
                        bN += 5
                    }
                    if (b['_highlightResult']?.name?.matchLevel === 'full') {
                        bN += 1
                    }
                    if (b['_highlightResult']?.city?.name?.matchLevel === 'full') {
                        bN += 1
                    }
                    return (aN > bN) ? -1 : 1;
                })
                .map(item => {
                    item = new Airport(item);
                    return item;
                })

                let found = results.hits.find(a => a.icao === req.query?.toUpperCase().trim());
                if (found) {
                    this.hits = [];
                    this.setAirport(found);
                    this.show = false;
                } else if (results?.hits?.length === 1) {
                    this.hits = [];
                    this.setAirport(results.hits[0]);
                    this.show = false;
                } else {
                    this.hits = results?.hits||[];
                    this.showChild();
                }

            } else {
                console.warn('SEARCH SETTINGS NOT CONFIGURED');
            }
        }
    }

    showChild() {
        this.show = true;
    }

    setAirport(a: Airport, skipEmit?: boolean) {
        if (!this.exclude || this.exclude?.icao !== a?.icao) {
            this.form.get(this.controlName).setValue(a);
            this.localForm.get(this.controlName).setValue(this.airportDisplay(a), {emitEvent: false});
            if (!skipEmit) {
                this.onChange.emit(a);
            }
        }
    }
}

