import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {Airport, ETripType, QuoteRequest, TripLeg} from '@nxt/model-laro';

import {format} from 'date-fns';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {InputDateComponent} from '@library/shared/input/input-date.component';
import {InputAirport} from '../../consumer/marketplace/input-airport';
import {LaroInputDateComponent} from '../../consumer/marketplace/laro-input-date';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {EDateFormats} from '@nxt/model-core';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        InputAirport, InputStackedTextComponent,
        InputDateComponent, InputAirport, LaroInputDateComponent
    ],
    selector: 'leg-form',
    template: `
        <form [formGroup]="form" *ngIf="form">
            <div class="grid grid-cols-12 w-full">
                <div class="p-1 md:p-2 col-span-12 sm:col-span-4">
                    <input-airport #departureAirport
                                   label="From"
                                   class="w-full bg-gray-100 text-left rounded-2xl text-dark uppercase"
                                   [form]="form"
                                   (onChange)="setAirport('departureAirport',$event)"
                                   controlName="departureAirport"
                    ></input-airport>
                </div>
                <div class="p-1 md:p-2 col-span-12 sm:col-span-4">
                    <input-airport #arrivalAirport
                                   label="To"
                                   class="w-full bg-gray-100 text-left rounded-2xl text-dark uppercase"
                                   [exclude]="departureAirport?.airport"
                                   (onChange)="setAirport('arrivalAirport',$event)"
                                   [form]="form" 
                                   controlName="arrivalAirport"
                    ></input-airport>
                </div>
                <div class="p-1 md:p-2 col-span-12 sm:col-span-4">
                    <laro-input-date
                            #takeoff
                            label="Date"
                            class="w-full bg-gray-100 text-left rounded-2xl text-dark uppercase"
                            [minDate]="today"
                            [includeTime]="!!(leg.times?.takeoffTime)"
                            [requireTime]="requireTime"
                            [hours]="['5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22']"
                            (onChange)="setTime($event)"
                            [form]="form"
                            controlName="takeoff"
                    ></laro-input-date>
                </div>

            </div>

        </form>
    `
})
export class LegFormComponent extends OnDestroyPage implements AfterViewInit {
    @ViewChild('departureAirport', {read:InputAirport})
    set departureAirport(a: InputAirport) {
        this._departureAirport = a;
        this.updateSelf('departure');
    }
    get departureAirport(): InputAirport {
        return this._departureAirport;
    }
    _departureAirport: InputAirport;

    @ViewChild('arrivalAirport', {read:InputAirport})
    set arrivalAirport(a: InputAirport) {
        this._arrivalAirport = a;
        this.updateSelf('arrival');
    }
    get arrivalAirport(): InputAirport {
        return this._arrivalAirport;
    }
    _arrivalAirport: InputAirport;

    @ViewChild('takeoff', {read:LaroInputDateComponent})
    set takeoff(t: LaroInputDateComponent) {
        this._takeoff = t;
        this.updateSelf('takeoff');
    }
    _takeoff: LaroInputDateComponent;

    @Output() onChange: EventEmitter<TripLeg> = new EventEmitter<TripLeg>();
    @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() form: UntypedFormGroup;
    @Input() includeTime: boolean = false;
    @Input() includeDate: boolean = false;
    @Input() requireDate: boolean = false;
    @Input() requireTime: boolean = false;
    @Input() quote: QuoteRequest;
    @Input() index: number;
    leg: TripLeg;
    today: Date = new Date();

    constructor(private fb: UntypedFormBuilder) {
        super();
    }

    ngAfterViewInit() {
        if (this.index !== undefined && this.quote?.legs?.length) {
            this.leg = this.quote.legs[this.index];
            let takeoff: Date = this.leg.times?.takeoffUTC ? new Date( this.leg.times?.takeoffUTC ) : null;
            if (!takeoff) {
                if (this.includeTime) {
                    this.setTime([new Date(), EDateFormats.DATE_TIME ]);
                } else {
                    this.setTime([new Date(), EDateFormats.DATE ]);
                }
            }

            this.form = this.fb.group({
                departureAirport: [this.leg.departureAirport, [Validators.required]],
                arrivalAirport: [this.leg.arrivalAirport, [Validators.required]],
                takeoff: [takeoff, this.requireDate ? [Validators.required] : null]
            });
        }
    }

    updateSelf(source?: any) {
        if (this.index !== undefined && this.quote?.legs?.length) {
            this.leg = this.quote.legs[this.index];
            if (this.leg && this.index) {
                let previous: TripLeg = this.quote.legs[this.index-1];
                if (previous) {
                    if (this._takeoff) {
                        if (previous?.times?.takeoffUTC) {
                            let takeoff: Date = previous.times.takeoffUTC ? new Date(previous.times.takeoffUTC) : new Date();
                            if (takeoff.valueOf() > this.today.valueOf()) {
                                this._takeoff.minDate = takeoff;
                            }
                            if (!this.leg.times.takeoffUTC || takeoff.valueOf() > this.leg.times.takeoffUTC) {
                                this._takeoff.setDate(takeoff);
                            }
                        }
                    }

                    if (this._arrivalAirport) {
                        switch (this.quote.tripType) {
                            case ETripType.ROUND_TRIP:
                                if (!this.leg.arrivalAirport) {
                                    this.leg.arrivalAirport = previous.departureAirport;
                                    this._arrivalAirport.setAirport(previous.departureAirport, true);
                                }
                                break;
                        }
                    }

                    if (this._departureAirport) {
                        switch (this.quote.tripType) {
                            case ETripType.ROUND_TRIP:
                                if (!this.leg.departureAirport) {
                                    this.leg.departureAirport = previous.arrivalAirport;
                                    this._departureAirport.setAirport(previous.arrivalAirport, true);
                                }
                                break;
                            case ETripType.MULTI_LEG:
                                if (!this.leg.departureAirport) {
                                    this.leg.departureAirport = previous.arrivalAirport;
                                    this._departureAirport.setAirport(previous.arrivalAirport, true);
                                }
                                break;
                        }
                    }
                }
            }
        }
    }

    setAirport(p: string, a:Airport) {
        this.leg[p] = a;
        this.onChange.emit(this.leg);
    }

    setTime([d, dateFormat]) {
        if (this.leg && d && d.valueOf() >= Date.now()) {
            this.leg.times.takeoffUTC = d.valueOf();
            this.leg.times.takeoffDate = format(d, EDateFormats.DATE);
            if (dateFormat.match(/H/)) {
                this.leg.times.takeoffTime = format(d, 'H:mm');
            } else {
                this.leg.times.takeoffTime = '';
            }
            this.onChange.emit(this.leg);
        }
    }

}
