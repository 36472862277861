import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {
    Deal,
    ETripType,
    getScoreColor,
    QuoteRequest,
    TripLeg
} from '@nxt/model-laro';
import {PageService} from '@library/shared/_services/page.service';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {FlightInfo} from './flight-info';
import {Client} from '@nxt/model-core';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {RouterLink} from '@angular/router';
import {addDays, format, startOfDay, subDays} from 'date-fns';
import {ClientService} from '@library/shared/_services/client.service';

@Component({
    standalone: true,
    imports: [CommonModule, IconsComponent, FlightInfo, PopButtonComponent, RouterLink],
    selector: 'laro-consumer-empty-leg-search',
    template: `
        <ng-container  *ngIf="results">

            <div class="bg-black text-white w-full border-b-4 border-b-white">
                <div class="max-w-4xl m-auto pt-6 text-left">
                    <div class="flex flex-col p-5">
                        <div class="text-xl font-bold uppercase">Book an Empty Leg</div>
                        <div class="italic text-sm" *ngIf="results?.length">Select an empty leg - an existing one-way flight that doesn't have any passengers.</div>
                    </div>

                    <div *ngIf="departureDayOptions?.length && leg?.departureAirport?.iata">
                        <div class="bg-black text-white flex justify-between w-full">
                            <div class="ml-20 flex flex-col p-2 space-y-2">
                                <div class="flex space-x-2 place-items-center">
                                    <div class="text-dark uppercase font-bold text-2xl">Depart:</div>
                                    <div class="border border-dark-600 rounded rounded-md text-dark/10 py-1 px-2 flex place-content-center cursor-pointer hover:bg-gray-500" (click)="clearResults()">
                                        {{ leg.departureAirport.iata }}
                                    </div>
                                    <div class="border border-dark-600 rounded rounded-md text-dark/10 py-1 px-2 flex place-content-center cursor-pointer hover:bg-gray-500" (click)="clearResults()">
                                        {{ leg.arrivalAirport.iata }}
                                    </div>
                                </div>
                                <div class="flex italic text-xs space-x-2">
                                    <div>{{ leg.departureAirport.city.name }}</div>
                                    <div>--</div>
                                    <div>{{ leg.arrivalAirport.city.name }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="flex justify-evenly mr-4 pt-4">
                                    <ng-container *ngFor="let item of departureDayOptions">
                                        <div class="flex flex-col space-y-1 p-2 text-center cursor-pointer hover:bg-gray-400 bg-black border-dark-600"
                                             (click)="setDate(item)"
                                             [style.border-left]="item.d.valueOf() === currentDate.valueOf() ? 'solid 1px #2a9286' : 'none'"
                                             [style.border-right]="item.d.valueOf() === currentDate.valueOf() ? 'solid 1px #2a9286' : 'none'"
                                             [style.border-top]="item.d.valueOf() === currentDate.valueOf() ? 'solid 4px #2a9286' : 'none'"
                                             [style.background-color]="item.d.valueOf() === currentDate.valueOf() ? 'rgb(107,114,128,1)' : 'none'"
                                        >
                                            <div class="uppercase text-xs">{{ item.dayOfWeek }}</div>
                                            <div class="text-dark font-bold text-sm">{{ item.monthDay }}</div>
                                        </div>
                                    </ng-container>
                                    <div class="p-3 cursor-pointer bg-gray-500 hover:bg-gray-600" (click)="clearResults()">
                                        <icon name="heroicon-outline-calendar" class="h-8 w-8 text-black"></icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-[#3a87ae] text-white w-full">

                <div class="italic p-10 text-center text-sm w-full" *ngIf="!results?.length">No empty legs found for selected date.</div>

                <div class="w-full p-5" *ngIf="results?.length">
                    <ng-container *ngFor="let result of results">
                        <div class="grid grid-cols-7 mb-2 p-2 rounded-md bg-[#69a4c1] hover:bg-gray-400 cursor-pointer"
                             (click)="setActiveDeal(result)"
                        >
                            <div class="col-span-7 md:col-span-1 flex flex-col place-items-center place-content-center">
                                <img [src]="result.client?.theme?.logo_light" class="h-4">
                                <span class="text-xs mt-2 italic">{{ result?.aircraft?.name }}</span>
                            </div>
                            <div class="col-span-3 md:col-span-1">
                                <div class="w-full h-full text-center md:text-left p-3">
                                    <div class="text-sm italic">
                                        {{ result.departureAirport?.icao }}
                                    </div>
                                    <div class="text-xs">{{ result.departureAirport?.name }}</div>
                                </div>
                            </div>
                            <div class="col-span-1 flex place-content-center place-items-center">
                                <icon name="heroicon-outline-arrow-right" class="h-4 w-4"></icon>
                            </div>
                            <div class="col-span-3 md:col-span-1">
                                <div class="w-full h-full text-center md:text-left p-3">
                                    <div class="text-sm italic">{{ result.arrivalAirport?.icao }}</div>
                                    <div class="text-xs">{{ result.arrivalAirport?.name }}</div>
                                </div>
                            </div>
                            <div class="col-span-3 md:col-span-1">
                                <div class="w-full h-full text-center p-3">
                                    <div>{{ result.departure_date | date:'M/d' }}</div>
                                </div>
                            </div>
                            <div [style.color]="getScoreColor(result.score)"
                                 class="col-span-1 md:col-span-1 hover:font-bold cursor-pointer flex flex-col align-top w-full h-full text-center pt-3">
                                <icon name="custom-flight-takeoff" class="h-8 w-8 self-center"></icon>
                                <div>{{ result.score.total }}%</div>
                            </div>
                            <div class="col-span-3 md:col-span-1">
                                <div class="w-full h-full text-center p-3">
                                    <div>{{ (result.estimate?.total || result.price?.total) | currency:'USD':'symbol' }}</div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="activeDeal?.id === result?.id" class="grid grid-cols-1 md:grid-cols-2 mb-2 rounded-md bg-[#69a4c1] bg-gray-400 p-4">
                            <div class="p-3 flex flex-col justify-between" *ngIf="activeDeal.verified && activeDeal.score.total === 100">
                                <div>
                                    <strong class="mb-2">Instant Book Available!</strong>
                                    <p>
                                        This is a verified real empty leg, so you can book it and submit payment information immediately. If you're not quite ready, you can also submit an inquiry with the operator.
                                    </p>
                                </div>
                                <div class="flex">
                                    <a class="btn-dark mr-3" routerLink="/checkout/instant_book/{{activeDeal.client.id}}/{{activeDeal.id}}">Book Now</a>
                                    <button class="btn-accent" (click)="setQuote(activeDeal)">Inquire</button>
                                </div>
                            </div>
                            <div class="p-3 flex flex-col justify-between" *ngIf="!activeDeal.verified || activeDeal.score.total !== 100">
                                <div>
                                    <strong class="mb-2">
                                        Inquire Today!
                                    </strong>
                                    <p>
                                        This empty leg is presented as-is by the operator. Inquire about its availability quickly and easily.
                                    </p>
                                </div>
                                <button class="btn-accent" (click)="setQuote(activeDeal)">Inquire</button>
                            </div>
                            <div class="p-3">
                                <strong class="mb-2">
                                    Levo Marketplace Score:
                                    <span  [style.color]="getScoreColor(result.score)">{{ activeDeal.score.total }}</span>
                                </strong>
                                <div class="space-y-2 italic text-sm">
                                    <div>
                                        <strong class="mr-3">Verified Real By Operator:</strong>
                                        <span *ngIf="activeDeal.score.verified">Yes! This is why you can book now!</span>
                                        <span *ngIf="!activeDeal.score.verified">Not yet. The flight may be changed, but inquire today!</span>
                                    </div>
                                    <div>
                                        <strong class="mr-3">Departure Date:</strong>
                                        <span *ngIf="!activeDeal.score.departureDateDiff">Exact match! Operator flight is scheduled on the day you requested!</span>
                                        <span *ngIf="activeDeal.score.departureDateDiff">
                                            Operator flight is {{activeDeal.score.departureDateDiff}} day(s) different from your request,
                                            but sometimes they can flex, so inquire today!
                                        </span>

                                    </div>
                                    <div>
                                        <strong class="mr-3">Departure Airport:</strong>
                                        <span *ngIf="!activeDeal.score.departureDistance">Exact match! Operator flight departs from requested airport!</span>
                                        <span *ngIf="activeDeal.score.departureDistance">
                                            Departure airport is {{activeDeal.score.departureDistance}} miles away from your requested airport,
                                            but operators are flexible, so inquire today!
                                        </span>

                                    </div>
                                    <div>
                                        <strong class="mr-3">Arrival Airport:</strong>
                                        <span *ngIf="!activeDeal.score.arrivalDistance">Exact match! Operator flight arrives at requested airport!</span>
                                        <span *ngIf="activeDeal.score.arrivalDistance">
                                            Arrival airport is {{activeDeal.score.arrivalDistance}} miles away from your requested airport,
                                            but operators are flexible, so inquire today!
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </ng-container>
    `
})
export class LaroConsumerEmptyLegSearch {
    @Output() onSelect: EventEmitter<[QuoteRequest,Client]> = new EventEmitter<[QuoteRequest,Client]>();
    @Output() onDateChanged: EventEmitter<any> = new EventEmitter<any>();
    @Input() results: Deal[];
    @Input() leg: TripLeg;
    today = new Date();
    currentDate: Date;
    section: any;
    ETripType = ETripType;
    activeDeal: Deal;
    departureDayOptions: any[];
    clientQuotes: any[];
    getScoreColor = getScoreColor;

    constructor(
        private pSvc: PageService,
        private cSvc: ClientService,
        public ref: ElementRef
    ) {
    }

    clearResults() {
        this.results = null;
        this.clientQuotes = null;
    }

    setDate(item) {
        this.leg.times.takeoffUTC = item.d.valueOf()
        this.leg.times.takeoffDate = format(item.d, 'MM/dd/yyyy');
        this.leg.times.takeoffTime = '8:00';
        this.leg.times.takeoffTZ = this.leg.departureAirport.timezone;
        this.doEmptySearch();
    }

    async doEmptySearch() {
        if (
            this.leg.departureAirport
            && this.leg.arrivalAirport
            && this.leg.times?.takeoffUTC
        ) {

            this.pSvc.blocking$.next(true);
            let d: Date = new Date(this.leg.times.takeoffUTC);
            this.currentDate = d;
            // Build list of day options
            this.departureDayOptions = [];
            if (Date.now() < startOfDay(this.currentDate).valueOf()) {
                d = subDays(d, 1);
            }
            while (this.departureDayOptions.length < 4) {
                this.departureDayOptions.push({
                    d: d,
                    dayOfWeek: format(d, 'EEE'),
                    monthDay: format(d, 'MMM d')
                });
                d = addDays(d,1);
            }
            await this.search();
            this.pSvc.blocking$.next(false);
        }
    }

    async search() {

        this.pSvc.loading$.next(true);
        try {
            this.results = await this.cSvc.callAPI('/laro/marketplace/empty/search','post', {
                leg: this.leg.toJSON(), currentDate: this.currentDate.valueOf() });
        } catch (e) {
            console.warn(e);
        }
        this.pSvc.loading$.next(false);
    }

    setActiveDeal(item: Deal) {
        this.activeDeal = item;
    }

    setQuote(item: Deal) {
        let q: QuoteRequest = new QuoteRequest(item);
        q.tripType = ETripType.ONE_WAY;
        q.legs = [new TripLeg(item)];
        q.estimate = item.estimate;
        this.onSelect.emit([q,item['client']]);
        this.activeDeal = null;
    }
    

}
